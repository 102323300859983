html, body {
  width: 100%;
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  &.isContentPage {
    min-width: 1520px;
  }
}

.ant-popover-message {
  text-align: center;
}
.ant-popover-message-title {
  padding-left: 0;
}
.ant-popover-buttons {
  text-align: center;
}

.errorInput {
  border: 1px solid #db4437 !important;
  box-shadow: 0 0 0 2px rgb(219 68 55 / 20%) !important;
}